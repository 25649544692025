import { createUniqueId } from 'solid-js';
import { Icon } from '../icon';
import type { IconProps } from '../icon';

export function IconTroonAccess(props: IconProps) {
	const id = createUniqueId();
	return (
		<Icon viewBox="0 0 32 18" {...props}>
			<path
				fill="#fff"
				fill-rule="evenodd"
				d="M3 0A2.6 2.6 0 0 0 .4 2.6v12.8C.4 16.8 1.5 18 3 18h23.5c1.4 0 2.6-1.2 2.6-2.6v-.8h-1.7v.8c0 .5-.4.9-.9.9h-15l5.2-14.6h9.8c.5 0 .9.4.9.9v1.7H29V2.6C29 1.2 27.9 0 26.5 0H3Zm14.8 13.7.5-1.7h3.5l.6 1.7h2.4l-3.4-9.4h-2.6l-3.4 9.4h2.4ZM20 6.8l1.1 3.5H19l1.2-3.5Zm7.3 1.8v-3H29v3h2.6v1.7H29v3h-1.7v-3h-2.6V8.6h2.6ZM10.7 6V4.3H3.8V6h2.4v7.6h2.1V6.1h2.4Z"
				clip-rule="evenodd"
			/>
			<path
				fill={`url(#${id})`}
				fill-rule="evenodd"
				d="M3 0A2.6 2.6 0 0 0 .4 2.6v12.8C.4 16.8 1.5 18 3 18h23.5c1.4 0 2.6-1.2 2.6-2.6v-.8h-1.7v.8c0 .5-.4.9-.9.9h-15l5.2-14.6h9.8c.5 0 .9.4.9.9v1.7H29V2.6C29 1.2 27.9 0 26.5 0H3Zm14.8 13.7.5-1.7h3.5l.6 1.7h2.4l-3.4-9.4h-2.6l-3.4 9.4h2.4ZM20 6.8l1.1 3.5H19l1.2-3.5Zm7.3 1.8v-3H29v3h2.6v1.7H29v3h-1.7v-3h-2.6V8.6h2.6ZM10.7 6V4.3H3.8V6h2.4v7.6h2.1V6.1h2.4Z"
				clip-rule="evenodd"
			/>
			<defs>
				<linearGradient id={id} x1="-2.6" x2="30.9" y1="18" y2="17.8" gradientUnits="userSpaceOnUse">
					<stop stop-color="#018993" />
					<stop offset="1" stop-color="#014C52" />
				</linearGradient>
			</defs>
		</Icon>
	);
}
